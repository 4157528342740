import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../components/Banner/Banner';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Layout from '../components/Layout/layout';
import Blogs from '../Views/Website/Blog/Blogs';

const BlogPage: React.FC = () => {
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Blogs</title><meta charSet='utf-8' />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "", Text: "Blogs" }
      ]} />
      <Blogs />
    </Layout>
  );
};

export default BlogPage;
